<template>
    <form class="register-form" autocomplete="off" @submit="register">
        <!-- <input autocomplete="false" name="hidden" type="text" style="display:none;"> -->
        <h2 class="title">{{ $t("register.title") }}</h2>
        <div class="register-fields">
            <!-- Prevent autocomplete -->
            <input
                name="username"
                style="opacity: 0; position: absolute"
                readonly
            />
            <input
                name="password"
                type="password"
                style="opacity: 0; position: absolute"
                readonly
            />
            <!-- Username -->
            <div class="register-username-container">
                <div class="register-username-wrapper">
                    <div class="input-group-prepend">
                        <span class="input-group-text">admin@</span>
                    </div>
                    <basic-input
                        class="register-field new-username-field input-group user"
                        name="new-username"
                        v-model="username"
                        :rules="isGoodLength"
                        :floating="true"
                        :autocomplete="'off'"
                        :placeholder="$t('register.id') + ' *'"
                        textTransform="lowercase"
                        :disabled="loadingRegister"
                        :needErrorMessage="false"
                        @update:modelValue="errorContent.user = ''"
                        @errorMessage="errorMessage = $event"
                        @focusout="checkPasswordSecurity()"
                    >
                    </basic-input>
                </div>
                <div class="info-subtitles">
                    <span
                        class="error-message-subtitle"
                        v-show="errorMessage !== null && errorMessage !== ''"
                    >
                        {{ errorMessage }}
                    </span>
                    <span
                        class="error-message-subtitle"
                        v-show="errorContent.user"
                    >
                        {{ errorContent.user }}
                    </span>
                    <span
                        class="username-subtitle"
                        v-show="fullUsername !== null"
                    >
                        {{
                            $t("register.idSubtitle", {
                                username: fullUsername,
                            })
                        }}
                    </span>
                </div>
            </div>

            <!-- Password -->
            <password-input
                class="register-field password-field input-group pwd"
                name="new-password"
                v-model="password"
                :floating="true"
                @input="checkPasswordSecurity()"
                ref="pwd"
                :rules="isAcceptedPassword"
                :autocomplete="'new-password'"
                :placeholder="$t('register.password') + ' *'"
                :disabled="loadingRegister"
                :errorContent="errorContent.pwd"
                @update:modelValue="errorContent.pwd = ''"
            />

            <!-- Confirm password -->
            <password-input
                class="register-field password-confirm-field input-group pwd_confirm"
                name="password-confirm"
                v-model="passwordConfirm"
                :floating="true"
                :rules="(isAcceptedPassword, isTheSamePassword)"
                :autocomplete="'new-password'"
                :placeholder="$t('register.confirmPassword') + ' *'"
                :disabled="loadingRegister"
                @update:modelValue="errorContent.pwd = ''"
            />

            <div class="password-security">
                <p>{{ $t("register.anssiPassword") }}</p>
                <p class="password-rule">
                    {{ $t("register.passwordRule1") }},
                    {{ $t("register.passwordRule2") }},
                    {{ $t("register.passwordRule3") }},
                    {{ $t("register.passwordRule4") }},
                    {{ $t("register.passwordRule5") }} (- _ @ $ ! ? * / # . , +)
                </p>
            </div>

            <div class="password-security">
                <div class="progress">
                    <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        :class="{
                            'bg-success': passwordLevel === passwordLevelNeeded,
                            'bg-warning': passwordLevel < passwordLevelNeeded,
                        }"
                        role="progressbar"
                        :style="'width: ' + progressBarValue + '%'"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
            </div>

            <!-- Double Authentication -->
            <div class="double-auth-wrapper">
                <basic-input
                    name="double-auth"
                    class="double-auth-input"
                    type="checkbox"
                    v-model="doubleAuth"
                />
                <p>{{ $t("register.doubleAuth") }}</p>
            </div>

            <!-- Last name -->
            <basic-input
                class="register-field last-name-field nom"
                name="last-name"
                v-model="lastName"
                :autocomplete="'off'"
                :rules="isNotEmpty"
                :floating="true"
                :placeholder="$t('register.lastName') + ' *'"
                :disabled="loadingRegister"
                :errorContent="errorContent.nom"
                @update:modelValue="errorContent.nom = ''"
            />

            <!-- First Name -->
            <basic-input
                class="register-field first-name-field prenom"
                name="first-name"
                v-model="firstName"
                :autocomplete="'off'"
                :rules="isNotEmpty"
                :floating="true"
                :placeholder="$t('register.firstName') + ' *'"
                :disabled="loadingRegister"
                :errorContent="errorContent.prenom"
                @update:modelValue="errorContent.prenom = ''"
            />

            <!-- Adress -->
            <basic-input
                class="register-field adress-field adresse"
                name="adress"
                v-model="adress"
                :autocomplete="'off'"
                :rules="isNotEmpty"
                :floating="true"
                :placeholder="$t('register.adress') + ' *'"
                :disabled="loadingRegister"
                :errorContent="errorContent.adresse"
                @update:modelValue="errorContent.adresse = ''"
            />

            <!-- Zip code -->
            <basic-input
                class="register-field zipcode-field cp"
                name="zipcode"
                v-model="zipCode"
                :autocomplete="'off'"
                :rules="isNotEmpty"
                :floating="true"
                :placeholder="$t('register.zipCode') + ' *'"
                :disabled="loadingRegister"
                :errorContent="errorContent.cp"
                @update:modelValue="errorContent.cp = ''"
            />

            <!-- City -->
            <basic-input
                class="register-field city-field ville"
                name="city"
                v-model="city"
                :autocomplete="'off'"
                :rules="isNotEmpty"
                :floating="true"
                :placeholder="$t('register.city') + ' *'"
                :disabled="loadingRegister"
                :errorContent="errorContent.ville"
                @update:modelValue="errorContent.ville = ''"
            />

            <!-- Country -->
            <basic-select
                class="register-field country-field pays_id"
                name="pays_id"
                v-model="country"
                :options="countries"
                :placeholder="$t('register.country') + ' *'"
                :disabled="loadingRegister"
                :rules="isNotEmpty"
                :errorContent="errorContent.pays_id"
                @update:modelValue="errorContent.pays_id = ''"
                @change="isAFrenchCode()"
            />

            <!-- Siret -->
            <basic-input
                class="register-field siret-field"
                name="siret"
                placeholder="siret"
                ref="siret"
                :floating="true"
                v-model="siret"
                :disabled="loadingRegister"
                :required="true"
                v-if="isAFrenchCode() === true"
            >
            </basic-input>

            <!-- Company -->
            <basic-input
                class="register-field company-field"
                name="company"
                v-model="company"
                :floating="true"
                :autocomplete="'off'"
                :placeholder="$t('register.company')"
                :disabled="loadingRegister"
            />

            <!-- Industry field -->
            <basic-select
                class="register-field industry-field secteur"
                name="secteur"
                v-model="industryField"
                :rules="isNotEmpty"
                :options="industryFields"
                :placeholder="$t('register.industry') + ' *'"
                :disabled="loadingRegister"
                :errorContent="errorContent.secteur"
                @update:modelValue="errorContent.secteur = ''"
            />

            <!-- Phone number -->
            <basic-input
                class="register-field phone-field"
                name="phone"
                v-model="phoneNumber"
                :autocomplete="'off'"
                :rules="isNumberAndNotEmpty"
                :floating="true"
                :placeholder="$t('register.phoneNumber') + ' *'"
                :disabled="loadingRegister"
            />

            <!-- Email -->
            <basic-input
                class="register-field email-field email"
                name="email"
                v-model="email"
                :autocomplete="'off'"
                :rules="isAcceptedEmail"
                :floating="true"
                :placeholder="$t('register.email') + ' *'"
                :disabled="loadingRegister"
                :errorContent="errorContent.email"
                @update:modelValue="errorContent.email = ''"
            />

            <!-- Terms -->
            <div class="form-check">
                <input
                    class="form-check-input"
                    v-model="acceptedTerms"
                    type="checkbox"
                    id="flexCheckDefault"
                    :disabled="loadingRegister"
                />
                <label class="form-check-label" for="flexCheckDefault">
                    <span id="accept">{{ $t("register.accept") + " " }}</span>
                    <a class="terms" :href="termsURL">
                        {{ $t("register.terms") }}.</a
                    >
                    {{ $t("text.userDataLaws") }}
                    <a
                        :href="'mailto:info@' + $t('text.emailIntratoneSuffix')"
                        class="mail-link"
                    >
                        info@{{ $t("text.emailIntratoneSuffix") }}
                    </a>
                </label>
            </div>
        </div>

        <!-- Buttons -->
        <div class="buttons">
            <!-- Back -->
            <basic-button
                class="back-button"
                link="login"
                :disabled="disableRegisterButton || loadingRegister"
            >
                <span v-if="!loadingRegister">
                    {{ $t("navigation.back") }}
                </span>
                <loader
                    v-else
                    class="login-loader"
                    :size="'small'"
                    :color="'white'"
                >
                </loader>
            </basic-button>

            <!-- Register -->
            <basic-button
                class="submit-button"
                type="submit"
                :disabled="disableRegisterButton || loadingRegister"
            >
                <span v-if="!loadingRegister">
                    {{ $t("button.register") }}
                </span>
                <loader
                    v-else
                    class="login-loader"
                    :size="'small'"
                    :color="'white'"
                >
                </loader>
            </basic-button>
            <!-- <basic-button class="submit-button" type="submit">Enregistrer</basic-button> -->
        </div>
    </form>
</template>

<script>
import BasicInput from "@/components/basic/BasicInput.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import PasswordInput from "@/components/basic/PasswordInput.vue"
import BasicSelect from "@/components/basic/BasicSelect.vue"
import Loader from "@/components/basic/Loader.vue"

import { register } from "@/services/intratone/account.js"
import { getPasswordSecurityLevel } from "@/services/intratone/account.js"
import { getMiscellaneous } from "@/services/intratone/miscellaneous.js"
import { debounce as _debounce } from "lodash"

import redirect from "@/mixins/redirection.js"

import { useForm } from "vee-validate"

import { useToast } from "vue-toastification"
const toast = useToast()

export default {
    name: "CreatAccount",
    mixins: [redirect],
    components: {
        BasicInput,
        BasicButton,
        PasswordInput,
        BasicSelect,
        Loader,
    },
    setup() {
        const { validate, errors } = useForm()

        return {
            validate,
            errors,
        }
    },
    data() {
        return {
            // Form data
            idPrefix: "admin@",
            username: null,
            password: null,
            passwordConfirm: null,
            lastName: null,
            firstName: null,
            adress: null,
            zipCode: null,
            city: null,
            country: null,
            siret: null,
            company: null,
            industryField: null,
            phoneNumber: null,
            email: null,
            acceptedTerms: true,
            // Password security level
            passwordSecure: false,
            passwordLevel: 0,
            passwordLevelNeeded: 10,
            // Double Auth
            doubleAuth: false,
            // Loading
            disableRegisterButton: false,
            loadingRegister: false,
            // ErrorApiReturns
            errorContent: {
                user: "",
                pwd: "",
                adresse: "",
                cp: "",
                nom: "",
                prenom: "",
                secteur: "",
                ville: "",
                pays_id: "",
                email: "",
            },
            errorMessage: "",
        }
    },
    computed: {
        countries() {
            return this.$store.getters["miscellaneous/getAllFormatedCountries"]
        },
        industryFields() {
            return this.$store.getters[
                "miscellaneous/getAllFormatedIndustryFields"
            ]
        },
        fullUsername() {
            return this.username !== null && this.username !== ""
                ? this.idPrefix + this.username
                : null
        },
        formData() {
            let params = {
                user: this.idPrefix + this.username,
                pwd: this.password,
                pwd_confirm: this.passwordConfirm,
                mfa: this.doubleAuth ? "CODE" : "NONE",
                nom: this.lastName,
                prenom: this.firstName,
                adresse: this.adress,
                societe: this.company,
                secteur: this.industryField,
                ville: this.city,
                pays_id: this.country,
                siret: this.siret,
                tel: this.phoneNumber,
                email: this.email,
                cp: this.zipCode,
            }
            return params
        },
        termsURL() {
            return this.getConditions()
        },
        progressBarValue() {
            return Math.floor(
                (100 / this.passwordLevelNeeded) * this.passwordLevel
            )
            // return 80
        },
    },
    methods: {
        async register(e) {
            e.preventDefault()

            try {
                // Disabling button to prevent button smash
                this.loadingRegister = true

                // Validating form
                let validationResponse = await this.validate()
                if (!validationResponse.valid) {
                    throw new Error(this.$t("alerts.form.incorrect"))
                }

                if (this.country === "0" || this.country === null) {
                    throw new Error(this.$t("alerts.form.country"))
                }
                if (this.industryField === "0" || this.industryField === null) {
                    throw new Error(this.$t("alerts.form.industryField"))
                }

                // Checking if terms are agreed
                if (this.acceptedTerms === false) {
                    throw new Error(this.$t("alerts.form.terms"))
                }

                const params = this.formData

                // Creating user account
                let registerResponse = await register(params)

                //reset class invalid for all
                const all = document.querySelectorAll(".invalid")
                for (let i = 0; i < all.length; i++) {
                    all[i].classList.remove("invalid")
                }

                // Displaying error message for any input with class 'invalid'
                if (registerResponse.state !== "ok") {
                    if (registerResponse.form) {
                        this.loadingRegister = false
                        for (let key in registerResponse.form) {
                            if (key === "pwd_confirm" || key === "pwd") {
                                this.errorContent[key] =
                                    registerResponse.form[key].msg
                            } else if (key === "secteur" || key === "pays_id") {
                                this.errorContent[key] =
                                    registerResponse.form[key].msg
                            } else {
                                this.errorContent[key] =
                                    registerResponse.form[key].msg
                            }
                        }
                        toast.error(this.$t("alerts.errors.2021"), {
                            icon: "fas fa-exclamation-circle",
                        })
                        return false
                    } else {
                        this.loadingRegister = false
                        throw toast.error(this.$t("alerts.errors.2019"), {
                            icon: "fas fa-exclamation-circle",
                        })
                    }
                } else {
                    toast.success(this.$t("alerts.connection.registered"), {
                        icon: "fas fa-exclamation-circle",
                    })
                }

                // Redirecting
                this.$router.push({
                    name: "Login",
                })

                this.loadingRegister = false
            } catch (error) {
                this.loadingRegister = false
                toast.info(error.message, {
                    icon: "fas fa-exclamation-circle",
                })
            }
        },

        autoCheckPassword: _debounce(async function () {
            const params = {
                user: this.username,
                pwd: this.password,
            }
            let securityResponse = await getPasswordSecurityLevel(params)

            if (securityResponse.state !== "ok") {
                throw new Error()
            }
            this.passwordSecure = securityResponse.data.secure
            this.passwordLevel = securityResponse.data.level
            this.passwordLevelNeeded = securityResponse.data.level_needed
        }, 500),

        async checkPasswordSecurity() {
            if (this.username && this.password) {
                try {
                    await this.autoCheckPassword()
                } catch (error) {
                    console.log(error)
                }
            } else {
                this.passwordSecure = false
                this.passwordLevel = 0
                this.passwordLevelNeeded = 10
            }
        },
        isAcceptedPassword(value) {
            if (value === "" || value === null || value === undefined) {
                return this.$t("input.mandatory")
            }
            return true
        },
        isNumber(value) {
            if (
                !(value === "" || value === null || value === undefined) &&
                /^\d+$/.test(value) === false
            ) {
                return this.$t("input.number")
            }
            return true
        },
        isNotEmpty(value) {
            if (!value) {
                return this.$t("input.mandatory")
            }
            return true
        },
        isGoodLength(value) {
            const minimum = 6
            const regex = new RegExp("^[a-z0-9_-]{" + minimum + ",50}$")
            if (value !== null) {
                if (value.length < minimum) {
                    return this.$t("input.length", { length: 6 })
                } else if (regex.test(value) === false) {
                    return this.$t("register.alertSpecialCharacter", {
                        specialCharacter: "-,_",
                    })
                }
                return true
            } else {
                return this.$t("input.mandatory")
            }
        },
        isNumberAndNotEmpty(value) {
            if (value === "" || value === null || value === undefined) {
                return this.$t("input.mandatory")
            }
            if (/^\d+$/.test(value) === false) {
                return this.$t("input.number")
            }
            return true
        },
        isAcceptedEmail(value) {
            if (value === "" || value === null || value === undefined) {
                return this.$t("input.mandatory")
            }
            if (
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                    value
                ) === false
            ) {
                return this.$t("input.email")
            }
            return true
        },
        isAFrenchCode() {
            const countries = this.$store.state.miscellaneous.countries
            for (let index = 0; index < countries.length; index++) {
                if (
                    this.country === countries[index].id &&
                    ["FR", "MC", "RE", "GP", "MQ", "ISM", "NC", "GF"].includes(
                        countries[index].code
                    )
                ) {
                    return true
                } else {
                    this.siret = null
                }
            }
        },
        isNotChosen(value) {
            if (!value || value === "0") {
                return this.$t("input.mandatory")
            } else {
                return true
            }
        },
        isTheSamePassword(value) {
            if (this.password !== value) {
                return this.$t("alerts.form.different")
            } else {
                const regex = /[^a-zA-Z0-9\-_@$!?*/#.,+]/
                if (regex.test(this.password) === true) {
                    return this.$t("register.alertSpecialCharacter", {
                        specialCharacter: "- _ @ $ ! ? * / # . , +",
                    })
                }
                return true
            }
        },
    },
    async created() {
        try {
            const params = {
                entities: ["countries", "sectors", "languages"],
                lng: this.$i18n.locale,
            }
            let miscellaneousResponse = await getMiscellaneous(params)
            if (miscellaneousResponse.state === "error") {
                throw new Error()
            }

            this.$store.commit(
                "miscellaneous/setCountries",
                miscellaneousResponse.data.list.countries
            )
            this.$store.commit(
                "miscellaneous/setIndustryFields",
                miscellaneousResponse.data.list.sectors
            )
            this.$store.commit(
                "miscellaneous/setLanguages",
                miscellaneousResponse.data.list.languages
            )
        } catch (error) {
            console.log(error)
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.logo {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
}

.register-form {
    text-align: center;
    padding: 20px 20px;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: $normal;
    font-weight: 300;
    line-height: 19px;

    .title {
        font-family: $font_avenir_black;
        font-size: $veryBig;
        width: 100% !important;
        margin: 0;
    }

    .register-fields {
        overflow-y: scroll;
        height: 495px;
        width: 100%;
        padding: 10px 50px 50px 50px;
        display: flex;
        flex-direction: column;
        gap: 1.5em;

        .register-field {
            input {
                height: 45px;
            }
        }

        .register-username-wrapper {
            display: flex;
            flex-direction: row;

            .input-group-prepend {
                flex: auto;

                .input-group-text {
                    height: 58px !important;
                }
            }

            .new-username-field {
                width: 100%;
                margin: 0;

                input {
                    border-radius: 0 15px 15px 0 !important;
                }

                .input-group-text {
                    height: 45px;
                }
            }
        }

        .info-subtitles {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            width: 100%;

            .username-subtitle {
                // text-align: center;
                margin: 10px auto 0 auto !important;
                width: 100%;
                font-size: $small;
            }

            .error-message-subtitle {
                color: $orange;
                font-size: $small;
            }
        }

        .register-field {
            input {
                height: 45px;
            }
        }

        select {
            height: 45px;
            color: $blue-neutral;
        }

        .password-field {
            .invalid {
                border: 1px solid rgb(247, 64, 64);
                box-shadow: 0 0 10px 2px rgb(255, 141, 141);
                // border-radius: 15px;
            }

            input {
                text-align: start;
            }

            .form-floating > label {
                width: 100%;
            }
        }

        .password-security {
            text-align: left;
            font-size: $small;

            .password-rule {
                font-family: $font_avenir_heavy;
            }

            p {
                margin: 0;
            }
        }

        .double-auth-wrapper {
            display: flex;
            align-items: start;
            gap: 1em;

            .double-auth-input {
                min-width: 18px !important;
                height: 18px !important;
            }

            p {
                text-align: start;
                margin: 0;
                font-size: $small;
            }
        }

        .invalid {
            border: 1px solid rgb(247, 64, 64);
            box-shadow: 0 0 10px 2px rgb(255, 141, 141);
            border-radius: 10px;
        }

        .input-group-text {
            height: 60px;
            border-radius: 10px 0 0 10px;
            border: none;
            padding: 0 30px 0 30px;
            background-color: $grey-neutral;
        }

        .form-check {
            display: flex;
            align-items: start;
            gap: 1em;
            font-size: $small;
            color: $blue-neutral;

            a {
                font-size: $small;
            }

            .form-check-input {
                min-width: 18px;
                height: 18px;
            }

            .form-check-label {
                font-size: $small;
                text-align: left;
            }

            .mail-link {
                text-decoration: underline;
                cursor: pointer;
                color: $blue-neutral;
            }
        }
    }
}

.buttons {
    margin-top: 20px;
    display: flex;
}

.back-button {
    width: 150px;
    margin-right: 50px;
    height: 60px;
}

.submit-button {
    width: 150px;
    height: 60px;
}

.accept {
    font-family: $font_avenir_heavy;
    font-size: $normal;
    color: $grey;
}

.terms {
    font-family: $font_avenir_heavy;
    font-size: $normal;
    color: $orange;
    text-underline-offset: 4px;
}

.user-data-laws {
    background-color: $grey-darker;
    color: $white;
    padding: 15px;
    border-radius: 15px;
    margin-top: 20px;

    i {
        font-size: $big;
    }

    .mail-link {
        text-decoration: underline;
        text-underline-offset: 5px;
        cursor: pointer;
        color: $white;
    }
}

.progress {
    transition: width 300ms;

    .progress-bar {
        // background-color: red;
        transition: width 300ms;
    }
}
</style>
