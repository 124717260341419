import httpClient from '../httpClient'

import { encodeRequestParameters } from '../request'

export function getMiscellaneous(data) {
    const params = encodeRequestParameters(data)
    return httpClient.get('/miscellaneous/list', {params})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}